<template>
  <h2 class="title">{{ name }}</h2>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 700;
  line-height: 1.3;
  font-size: 1.75rem;
  color: #44566c;
  margin: 30px 0 10px 0;
}
</style>
