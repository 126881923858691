<template>
  <div class="contacts">
    <div class="section">
      <content-title name="Contacto" />
      <bar />
    </div>
    <div class="contact-form">
      <div class="name-email">
        <el-input placeholder="Nombre" v-model="form.name" />
        <el-input placeholder="Email" class="email" v-model="form.email" />
      </div>
      <el-input
        type="textarea"
        :rows="8"
        placeholder="Mensaje"
        v-model="form.message"
      >
      </el-input>
      <el-button
        type="default"
        class="btn-send"
        icon="el-icon-s-promotion"
        round
        @click="sendMessage"
        >Enviar mensaje</el-button
      >
    </div>
    <div class="footer">
      <footer-content />
    </div>
  </div>
</template>

<script>
import ContentTitle from "@/components/contents/Title";
import Bar from "@/components/contents/Bar.vue";
import FooterContent from "@/components/contents/Footer.vue";

export default {
  name: "Contacts",
  components: {
    ContentTitle,
    Bar,
    FooterContent,
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    sendMessage() {
      window.open(
        `mailto:andrea@andreathome.es?subject=${this.form.name}-${this.form.email}&body=${this.form.message}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.contacts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .section {
    padding: 0 4%;
    width: 100%;
  }

  .contact-form {
    padding: 4%;

    .name-email {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
      .email {
        margin-left: 10px;
      }
    }

    .btn-send {
      background-color: #ff3b19;
      border: 1px solid #ff3b19;
      color: white;
      width: 200px;
      float: right;
      margin-top: 1rem;
      height: 60px;
    }

    .btn-send:hover {
      background-color: #ff5537;
      border: 1px solid #ff5537;
      color: white;
    }

    .btn-send:focus {
      background-color: #ff5537;
      border: 1px solid #ff5537;
      color: white;
    }
  }

  .footer {
    background-color: #f5f8f9;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
</style>
